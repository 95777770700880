const cueSet2 = [
  {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 491520,
          "time": 16.551712000000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 499200,
          "time": 16.8103325,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 506880,
          "time": 17.068953,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 514560,
          "time": 17.327573500000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 518400,
          "time": 17.456883750000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 526080,
          "time": 17.715504250000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 537600,
          "time": 18.103435,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 549120,
          "time": 18.491365750000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 552960,
          "time": 18.620676000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 568320,
          "time": 19.137917,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 579840,
          "time": 19.525847750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 583680,
          "time": 19.655158000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 587520,
          "time": 19.784468250000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 591360,
          "time": 19.913778500000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 599040,
          "time": 20.172399000000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 614400,
          "time": 20.689640000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 618240,
          "time": 20.818950250000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 622080,
          "time": 20.948260500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 629760,
          "time": 21.206881000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 652800,
          "time": 21.982742500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 660480,
          "time": 22.241363000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 668160,
          "time": 22.499983500000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 672000,
          "time": 22.629293750000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 675840,
          "time": 22.758604000000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 683520,
          "time": 23.0172245,
          "velocity": 1
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 687360,
          "time": 23.146534750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 691200,
          "time": 23.275845000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 702720,
          "time": 23.663775750000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 706560,
          "time": 23.793086000000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 710400,
          "time": 23.922396250000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 714240,
          "time": 24.0517065,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 721920,
          "time": 24.310327000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 737280,
          "time": 24.827568000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 744960,
          "time": 25.086188500000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 752640,
          "time": 25.344809,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 760320,
          "time": 25.603429500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 764160,
          "time": 25.732739750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 771840,
          "time": 25.991360250000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 783360,
          "time": 26.379291000000002,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 794880,
          "time": 26.767221750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 798720,
          "time": 26.896532000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 814080,
          "time": 27.413773000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 825600,
          "time": 27.801703750000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 829440,
          "time": 27.931014000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 833280,
          "time": 28.060324250000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 837120,
          "time": 28.189634500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 844800,
          "time": 28.448255000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 860160,
          "time": 28.965496,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 864000,
          "time": 29.094806250000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 867840,
          "time": 29.224116500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 875520,
          "time": 29.482737000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 898560,
          "time": 30.258598500000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 906240,
          "time": 30.517219000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 913920,
          "time": 30.775839500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 917760,
          "time": 30.905149750000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 921600,
          "time": 31.034460000000003,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 929280,
          "time": 31.293080500000002,
          "velocity": 1
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 933120,
          "time": 31.422390750000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999804,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 936960,
          "time": 31.551701000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 948480,
          "time": 31.939631750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 952320,
          "time": 32.06894200000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 956160,
          "time": 32.19825225,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 960000,
          "time": 32.327562500000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 967680,
          "time": 32.586183000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 983040,
          "time": 33.103424000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 990720,
          "time": 33.3620445,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 998400,
          "time": 33.620665,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1006080,
          "time": 33.8792855,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1009920,
          "time": 34.008595750000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1017600,
          "time": 34.267216250000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1029120,
          "time": 34.65514700000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1040640,
          "time": 35.04307775,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1044480,
          "time": 35.172388000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1059840,
          "time": 35.689629000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1071360,
          "time": 36.077559750000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1075200,
          "time": 36.20687,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1079040,
          "time": 36.336180250000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1082880,
          "time": 36.4654905,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1090560,
          "time": 36.72411100000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1105920,
          "time": 37.241352000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1109760,
          "time": 37.37066225,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1113600,
          "time": 37.499972500000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1121280,
          "time": 37.758593000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1144320,
          "time": 38.5344545,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1152000,
          "time": 38.793075,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1159680,
          "time": 39.05169550000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1163520,
          "time": 39.181005750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1167360,
          "time": 39.31031600000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1175040,
          "time": 39.56893650000001,
          "velocity": 1
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1178880,
          "time": 39.69824675,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1182720,
          "time": 39.827557000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1194240,
          "time": 40.21548775000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1198080,
          "time": 40.344798000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1201920,
          "time": 40.47410825000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1205760,
          "time": 40.603418500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1213440,
          "time": 40.862039,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1228800,
          "time": 41.37928000000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1236480,
          "time": 41.63790050000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1244160,
          "time": 41.89652100000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1251840,
          "time": 42.155141500000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1255680,
          "time": 42.28445175,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1263360,
          "time": 42.54307225,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1274880,
          "time": 42.931003000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1286400,
          "time": 43.31893375000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1290240,
          "time": 43.448244,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1305600,
          "time": 43.96548500000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1317120,
          "time": 44.35341575,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1320960,
          "time": 44.48272600000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1324800,
          "time": 44.61203625,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1328640,
          "time": 44.741346500000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1336320,
          "time": 44.999967000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1351680,
          "time": 45.517208000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1355520,
          "time": 45.64651825000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1359360,
          "time": 45.7758285,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1367040,
          "time": 46.034449,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1390080,
          "time": 46.81031050000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1397760,
          "time": 47.068931000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1405440,
          "time": 47.327551500000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1409280,
          "time": 47.45686175000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1413120,
          "time": 47.586172000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1420800,
          "time": 47.844792500000004,
          "velocity": 1
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1424640,
          "time": 47.97410275000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1428480,
          "time": 48.103413,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1440000,
          "time": 48.491343750000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1443840,
          "time": 48.62065400000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1447680,
          "time": 48.749964250000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1451520,
          "time": 48.87927450000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1459200,
          "time": 49.13789500000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1474560,
          "time": 49.655136000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1482240,
          "time": 49.913756500000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1489920,
          "time": 50.172377000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1497600,
          "time": 50.430997500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1501440,
          "time": 50.56030775000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1509120,
          "time": 50.818928250000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1520640,
          "time": 51.20685900000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1532160,
          "time": 51.594789750000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1536000,
          "time": 51.72410000000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1551360,
          "time": 52.241341000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1562880,
          "time": 52.62927175000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1566720,
          "time": 52.758582000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1570560,
          "time": 52.88789225000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1574400,
          "time": 53.0172025,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1582080,
          "time": 53.27582300000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1597440,
          "time": 53.79306400000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1601280,
          "time": 53.922374250000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1605120,
          "time": 54.05168450000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1612800,
          "time": 54.31030500000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1635840,
          "time": 55.086166500000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1643520,
          "time": 55.344787000000004,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1651200,
          "time": 55.60340750000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1655040,
          "time": 55.732717750000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1658880,
          "time": 55.86202800000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1666560,
          "time": 56.12064850000001,
          "velocity": 1
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1670400,
          "time": 56.249958750000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1674240,
          "time": 56.37926900000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1685760,
          "time": 56.76719975000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1689600,
          "time": 56.896510000000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512499999448,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1693440,
          "time": 57.02582025000001,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1697280,
          "time": 57.155130500000006,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 37,
          "name": "C#2",
          "ticks": 1704960,
          "time": 57.413751000000005,
          "velocity": 0.6299212598425197
        },
        {
          "duration": 0.06465512500000159,
          "durationTicks": 1920,
          "midi": 36,
          "name": "C2",
          "ticks": 1720320,
          "time": 57.930992,
          "velocity": 0.6299212598425197
        }
];

export default cueSet2;
